export default async (context) => {
  await context.$c.loadStringFile();

  if (context.route.path === '/' && context.store.state.auth.user) {
    context.redirect(context.$config.lmsUrl);
    return;
  }
  const eventID = `${context.store.state.tracking.sessionId || ''}${Date.now()}`;
  const currentUrl = context.route.name;
  const isLoggedIn = context.store.state.auth.user;
  const urlsToRedir = ['qa-hub', 'question-question', 'qa-hub-question', 'courses', 'courses-course', 'pricing', 'upcoming-courses', 'career-tracks', 'career-tracks-track', 'projects'];

  if (isLoggedIn && urlsToRedir.includes(currentUrl)) {
    let redirectUrl = context.$config.lmsUrl + context.route.fullPath;
    if (currentUrl === 'courses-course') {
      const redirectUrlArray = context.route.fullPath.split('/');
      redirectUrlArray.splice(2, 0, 'preview');
      redirectUrl = context.$config.lmsUrl + redirectUrlArray.join('/');
    }
    context.redirect(301, redirectUrl);
  }

  if (context.route.path.startsWith('/i/')) {
    const key = context.route.params.blogSubItem || context?.route?.path?.split('/').filter((v) => v && v !== 'i')[0];
    context.redirect(`/business/signup/?key=${key}`);
  }

  if (context.route.path.startsWith('/r/')) {
    context.redirect(`/checkout/?plan=annual&rkey=${context.route.params.blogSubItem || context.route.path.split('/').filter((v) => v && v !== 'r')[0]}`);
  }

  if (context.route.path.startsWith('/ii/')) {
    const key = context.route.params.blogSubItem || context?.route?.path?.split('/').filter((v) => v && v !== 'ii')[0];
    context.redirect(`/instructors/signup/?key=${key}`);
  }

  let fromUrl = null;
  const toUrl = context.route.fullPath.replace(context.route.hash, '');
  if (context.from && context.from.fullPath) {
    fromUrl = context.from.fullPath.replace(context.from.hash, '');
  }

  if (process.client) {
    const { event } = context.store.state.tracking;

    const isItABackButton = window.popStateDetected;

    window.popStateDetected = false;

    context.$tracking.resetTrackingSections();

    if (!isItABackButton) {
      // Save the previous page to store and use it in dataLayer
      context.store.dispatch('setReferrer', fromUrl);
      // Save current page in cookie to use it in LMS
      context.$tracking.setProjectReferrerCookie(toUrl);

      if (event && !event.includes('.scroll')) {
        context.$tracking.sendEvent({
          event: context.store.state.tracking.event,
          sourceUrl: context.from.fullPath,
          destinationUrl: context.route.fullPath,
          route: context.from,
          eventID,
          capi: true,
          session: true,
          userAgent: navigator.userAgent,
        });
      }
    }

    if (fromUrl !== toUrl) {
      const duration = toUrl.includes('checkout') ? 1500 : 500;
      setTimeout(() => {
        context.store.commit('dataLayer/removeRegistered', 'full_scroll');
        context.store.dispatch('dataLayer/addEvent', {
          event: 'Pageview',
          value: { eventID },
          referrerFromThisProject: !!toUrl.includes('redirecting'),
        });
      }, duration);
    }
  }
};
